// @flow strict
import React, { useEffect } from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import Sidebar from "../components/Sidebar";
import Feed from "../components/Feed";
import Page from "../components/Page";
import Pagination from "../components/Pagination";
import { useSiteMetadata } from "../hooks";
import paginationStyles from "../components/Pagination/Pagination.module.scss";
import firebase from "gatsby-plugin-firebase";

const Index = ({ data, location }) => {
  const { title: siteTitle, subtitle: siteSubtitle } = useSiteMetadata();

  const { edges } = data.allMarkdownRemark;
  const pageTitle = siteTitle;

  useEffect(() => {
    firebase.analytics().logEvent("index visited");
  }, []);

  return (
    <Layout title={pageTitle} description={siteSubtitle}>
      <Sidebar isIndex />
      <Page>
        <h1
          style={{
            marginTop: "0",
            marginBottom: "0",
            textDecoration: "underline",
          }}
        >
          Hello.
        </h1>
        <h2 style={{ marginTop: "0" }}>
          Welcome to my blog about language acquisition.
        </h2>
        <p>
          This blog documents my progress on my current language, Japanese, some
          writings to try to streamline the best advice I've heard from the
          community, and my personal findings while researching motivation in
          language learning. This is currently a passion project and I am against
          ads and unnecessary self-promotion, so if you like what you read and
          want to support me, just share the articles that you find interesting.
        </p>

        <h2 style={{ textDecoration: "underline" }}>Recent Posts</h2>
        <Feed edges={edges} />
        <div className={paginationStyles["pagination"]}>
          <div
            className={paginationStyles["pagination__next"]}
            style={{ marginRight: "0px", marginLeft: "auto" }}
          >
            <Link
              rel="View More"
              to="/posts"
              className={paginationStyles["pagination__next-link"]}
            >
              → VIEW MORE
            </Link>
          </div>
        </div>
      </Page>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      limit: 2
      filter: { frontmatter: { template: { eq: "post" }, draft: { ne: true } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            slug
            categorySlug
          }
          frontmatter {
            title
            date
            category
            description
          }
        }
      }
    }
  }
`;

export default Index;
